<template>
    <div>
        <a href="#contato" v-smooth-scroll="{duration: 500, offset: -55}" class="btn d-flex align-items-center shadow-sm fs-17 justify-content-center fw-600 btn-outline-dark border-radius-20 px-25">
            <i class="fas fa-file-signature mr-2 fw-400"></i> Veja nosso formulário
        </a>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>

</style>
